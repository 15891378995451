.personImage{
   display: flex;
   align-items: center;
}

.image{
    border: 2px solid rgba(255, 179, 0, 0.89);
    padding: 2px;
}

.personContent{
    text-align: justify;
}

.custom-carousel .carousel-control-prev,
.custom-carousel .carousel-control-next {
  left: -30px;
  right: -30px;
}

@media screen and (max-width: 576px){
  .testimrg{
    margin-bottom: 10px;
  }
}