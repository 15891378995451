@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.carouselImg{
    width: 100%;
    height: 100%;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* height: 80vh !important; */
}

.carousels{
  width: 100%;
  height: 80%;
}

.carouselContent{
  position: absolute;
  top: 40%;
  left: 10%;
  color: white;
  font-size: 50px;
  font-family: "Anton", sans-serif;
  font-weight: 700;
}

.boxShake{
  width: 200px;
  height: 300px;
  background: transparent;
}

.box {
  border: 15px solid rgb(255, 179, 0);
  position: absolute;
  overflow: hidden;
  top: 650px;
  left: 200px;
  animation: moveBox 4s linear forwards, shakeBox 9s cubic-bezier(.36, .07, .36, .07) infinite;
}


@keyframes moveBox {
  0% { top: 650px; }
  25% { top: 5%; }
  50% { top: 10%; }
  75% { top: 13%; }
  100% { top: 18%; }
}

@keyframes shakeBox {
  0%, 100% { transform: translateX(-50%); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-55%); }
  20%, 40%, 60%, 80% { transform: translateX(-45%); }
}


@media screen and (min-width:320px) and (max-width: 768px){
  .boxShake{
    width: 150px;
    height: 200px;
    background: transparent;
  }

.box {
  border: 10px solid rgb(255, 179, 0);
  position: absolute;
  overflow: hidden;
  top: 650px;
  left: 100px;
  animation: moveBox 4s linear forwards, shakeBox 9s cubic-bezier(.36, .07, .36, .07) infinite;
}
  
.carouselContent{
  position: absolute;
  top: 40%;
  left: 10%;
  color: white;
  font-size: 30px;
  font-family: "Anton", sans-serif;
  font-weight: 600;
}

}

@media screen and (min-width:2560px){
  .boxShake{
    width: 300px;
    height: 500px;
    background: transparent;
  }

.box {
  border: 15px solid rgb(255, 179, 0);
  position: absolute;
  overflow: hidden;
  top: 650px;
  left: 250px;
  animation: moveBox 4s linear forwards, shakeBox 9s cubic-bezier(.36, .07, .36, .07) infinite;
}
  
.carouselContent{
  position: absolute;
  top: 40%;
  left: 10%;
  color: white;
  font-size: 80px;
  font-family: "Anton", sans-serif;
  font-weight: 900;
}

}

