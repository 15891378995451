.allservice{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 10px 2px;
    text-align: center;
    color: black;
}

.allImg{
    
    width: 80px;
    height: 80px;
    background-color: rgb(255, 187, 0);
    padding: 20px;
    border-radius: 50%;
}

.allservice:hover .allImg {
    transform: scale(1.1); /* Increase the scale of the image on hover */
    transition: transform 0.3s ease; /* Transition for the scale */
}