.serviceCall{
    text-align: center;
}

.serviceCol1{
    background-color: rgb(255, 179, 0);
    color: white;
}

.serviceCol2{
    background-color: black;
    color: white;
}
.calling{
    text-decoration: none;
    color: white;
}
.servicebutton{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12vh;
}

.onlineform{
    font-family: monospace;
    background-color: rgb(41, 35, 35);
    color: white;
    border: none;
    font-size: 18px;
}

@media only screen and (max-width: 435px){
    .heroimg{
        width: 100%;
        height: auto;
    }
}

@media only screen and (max-width: 320px){
    .serviceHead{
        font-size: 15px;
    }
}