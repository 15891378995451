
.imageContainer{
position: relative;
overflow: hidden;
box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.1);
}

.ourService{
background: rgba(0, 0, 0, 0.016);
}

.aboutImage{
width: 100%;
height: 100%;
transition: transform 0.5s ease;
}

.aboutImage:hover{
  opacity: 0.8;
}

.imageContainer:hover .aboutImage{
transform: translateY(-18%);
}

.content{
position: absolute;
bottom: 0;
left: 0;
right: 0;
background-color: white;
color: black;
display: flex;
align-items: center;
justify-content: space-between;
opacity: 0;
transition: opacity 0.5s ease;
}

.imageContainer:hover .content{
opacity: 1;
}

.plusSymbol{
  background-color: rgba(255, 200, 1, 0.995);
  padding: 0px 20px;
  font-size: 50px;
  font-weight: 10px;
  color: white;
  transform: translateX(100%) translateX(-0%); /* Initial position */
  transition: transform 0.3s ease-in-out;
  }
  
.imageContainer:hover .plusSymbol {
  transform: translateX(0%) translateX(-0%); /* Move the plus symbol from right to left */
}

.content p{
margin-bottom: 0rem;
}

.building{
  border: 15px solid rgba(128, 128, 128, 0.475);
height: 100%;
background-color: white;
}

.buildingContent{
 
background-color: white;
color: black;
margin: 0;
}

.buildingContent h6{
  font-size: 30px;
  font-weight: bold;
}

.viewAll{
width: 7rem;
padding: 5px 5px;
background-color: rgba(255, 200, 1, 0.995);
color: white;
font-weight: bold;
font-family: monospace;
letter-spacing: 2px;
text-align: center;
border: none;
}

.btn:before {
    border-color: #c47135;
    border-left-width: 2px;
    border-top-width: 2px;
    left: -5px;
    top: -5px;
}

@media only screen and (max-width: 575px){
  .servicesImg{
    margin-top: 15px !important;
  }
}
