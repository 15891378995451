section .background{
    width: 100%;
    height: 100vh;
    display: flex;
  }
  /* CSS for Background Image Blur Effect */
  .background {
      /* background-image: linear-gradient(rgba(222, 228, 63, 0.5), rgba(222, 228, 63, 0.5)), 
        url('../assests/count.jpg'); */
      /* background-image: url('../assests//bg2.jpg'); Replace 'your-background-image.jpg' with the path to your image */
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      width: 100%;
      height: 50vh; /* Adjust the height as needed */
      position: relative;
    }
  
  .contents{
  /* background: rgba(222, 228, 63, 0.5); */
  padding: 100px;
  color: white;
  text-align: center;
  }

@media only screen and (min-width: 1440px){
  .contents{
    /* background: rgba(222, 228, 63, 0.5); */
    padding: 100px;
    color: white;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    }

    .contentsh1{
      font-size: 50px;
    }
}


@media only screen and (max-width: 477px){
  .contents{
    /* background: rgba(222, 228, 63, 0.5); */
    /* padding: 100px; */
    color: white;
    font-size: 20px;
    /* font-weight: bold; */
    text-align: center;
    margin: 0px 0px;
    }
}