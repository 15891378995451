.container {
    background-color: black;
    color: white;
    margin-top: 50px;
  }

  .bigboxx{
display: flex;
align-items: center;
justify-content: space-around;
  }

  .tag{
    text-decoration: none;
    color:white
  }
  .colum1 {
    padding: 0px 40px;
    margin-top: 50px;
  }
  
  .smallicon {
    margin-top: 1px;
    padding: 10px 10px;
  }

  .box {
    background-color: rgb(46, 44, 44);
    padding: 50px 120px;

    margin-top: 40px;
    width: 100px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    box-shadow: 46px 34px 8px -9px rgba(224,218,218,0.97);
-webkit-box-shadow: 46px 34px 8px -9px rgba(56, 50, 50, 0.97);
-moz-box-shadow: 46px 34px 8px -9px rgba(162, 157, 157, 0.671);
  }
  
  .envelopeIcon {
    font-size: 30px; 
    margin-bottom: 20px; 
  }
  
  .spans {
    text-align: center; 
    color: white; 
  }

  
  .boxs{
    background-color: rgb(46, 44, 44);
    padding: 50px 120px;
    margin-top: 40px;
   margin-right: 40px;
    width: 100px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    box-shadow: 46px 34px 8px -9px rgba(224,218,218,0.97);
    -webkit-box-shadow: 46px 34px 8px -9px rgba(56, 50, 50, 0.97);
    -moz-box-shadow: 46px 34px 8px -9px rgba(162, 157, 157, 0.671);
  }
  @media screen and (min-width: 320px) and (max-width:426px){
    .box {
        padding: 10px 50px;
        width: 30px;
        font-size: 0.5rem;
        display: flex;
        flex-direction: column; 
        -webkit-box-shadow: 36px 20px 9px -8px rgba(56, 50, 50, 0.97);
    
    }
    .boxs{
         
        padding: 10px 50px;
        width: 30px;
        font-size: 0.5rem;
            display: flex;
            flex-direction: column; 
            -webkit-box-shadow:36px 20px 9px -8px rgba(56, 50, 50, 0.97);
        
    }
  }
  @media screen and (min-width: 992px) and (max-width:1243px){
    .box {
        padding: 40px 100px;
    width: 50px;
        font-size: 1rem;
        display: flex;
        flex-direction: column; 
       
    
    }
    .boxs{
        padding: 40px 100px;
        width: 50px;
            font-size: 1rem;
            display: flex;
            flex-direction: column;         
    }
  }
  @media screen and (min-width: 185px) and (max-width:320px){
    .box {
        padding: 20px 50px;
    width: 30px;
    margin-right: 5px;
        font-size: 0.5rem;
        display: flex;
        flex-direction: column;     
    }
    .boxs{
        padding: 20px 50px;
        width: 30px;
        font-size: 0.5rem;
            display: flex;
            flex-direction: column;         
    }
  }
  @media screen and (min-width: 426px) and (max-width:568px){
    .box {
        padding: 40px 80px;
    width: 30px;
    margin-right: 5px;
    font-size: 0.7rem;
        display: flex;
        flex-direction: column;     
    }
    .boxs{
        padding: 40px 80px;
        width: 30px;
        font-size: 0.7rem;
            display: flex;
            flex-direction: column;         
    }
  }