*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
*::selection {
  background-color: #F5BF23;
  color: white;
}


/* .nav1{
  color: white;
}

.index{
  position: sticky;
  top: 0px;
  z-index: 1;
} */

/* Custom Navbar Styles */

/* Add box-shadow */
/* .navbar-custom {
  box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.1); */
  /* border-radius: 15px; */
/* } */

/* Change font style, size, and family */
/* .navbar-custom .navbar-brand,
.navbar-custom .nav-link {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
} */

/* index */
