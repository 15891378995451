.yearsContent{
    background-color: rgba(209, 205, 205, 0.694);
}

.serviceBg{
    background-color: black;
    color: white;
}

.serviceBg1{
    background-image:  linear-gradient(rgba(236, 186, 37, 0.854), rgba(236, 186, 37, 0.863)), url('../assests/w3.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: black;
}

.onlineform{
    font-family: monospace;
    background-color: rgb(41, 35, 35);
    color: white;
    border: none;
    font-size: 18px;
}


.submit{
    width: 7rem;
    padding: 5px 5px;
    background-color: rgba(255, 200, 1, 0.995);
    color: white;
    font-weight: bold;
    font-family: monospace;
    letter-spacing: 2px;
    text-align: center;
    border: none;
    }
  
  .submit:hover{
    background-color: black;
    border: 2px solid white;
  }

/* border 1 */

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 80%, gray 85%) 1;
}

.border1::after {
    content: '80%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border2 */

.border2 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 95%, gray 78%) 1;
}

.border2::after {
    content: '95%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 3 */

.border3 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 90%, gray 65%) 1;
}

.border3::after {
    content: '90%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 4 */

.border4 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 82%, gray 40%) 1;
}

.border4::after {
    content: '82%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* Border 5 */

.border5 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 55%, gray 55%) 1;
}

.border5::after {
    content: '55%';
    position: absolute;
    top: 5px;
    right: -10px;
}

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 85%, gray 85%) 1;
}

.border1::after {
    content: '85%';
    position: absolute;
    top: 5px;
    right: -10px;
}
