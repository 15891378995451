.sign{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: black
}
.aboutvalue{
    position: relative;
}

.carousel-indicators {
    display: none !important;
    z-index: -3 !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.aboutYears{
    border-left: 10px solid rgb(255, 157, 0);
}

.abouthead{
    font-size:60px ;
    font-weight: 800;
}


.carouselBg{
    background-color: rgb(255, 157, 0);
    width: 80%;
    height: 80%;
    position: absolute;
    top: 90px;
    right: 50px;
    left: 0;
    bottom: 0;
}

.count{
    background-color: black;
    padding: 7px;
}

.number{
    color: rgb(255, 191, 0);
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    margin: 0px 0px 5px 0px;
}

.valueText{
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
/* border 1 */

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 85%, gray 85%) 1;
}

.border1::after {
    content: '85%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border2 */

.border2 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 78%, gray 78%) 1;
}

.border2::after {
    content: '78%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 3 */

.border3 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 65%, gray 65%) 1;
}

.border3::after {
    content: '65%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 4 */

.border4 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 40%, gray 40%) 1;
}

.border4::after {
    content: '40%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* Border 5 */

.border5 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 55%, gray 55%) 1;
}

.border5::after {
    content: '55%';
    position: absolute;
    top: 5px;
    right: -10px;
}

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 85%, gray 85%) 1;
}

.border1::after {
    content: '85%';
    position: absolute;
    top: 5px;
    right: -10px;
}


@media only screen and (min-width: 320px) and (max-width: 425px){
    .abouthead{
        font-size:30px ;
        font-weight: 800;
    }
    .aboutContent{
        font-size: 20px;
    }
}

@media only screen and (max-width: 320px){
    .aboutYears p {
        font-size:15px ;
        font-weight: 800;
    }
    .aboutContent{
        font-size: 18px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px){
   
.carouselBg{
    background-color: rgb(255, 157, 0);
    width: 80%;
    height: 90%;
    position: absolute;
    top: 90px;
    right: 50px;
    left: 0;
    bottom: 0;
}
}
