.mission{
    background-color: rgba(77, 75, 75, 0.119);
    margin-top: 50px;
}

.missionColumn{
    background-color: white;
    color: black;
}

.linknav:hover{
    color:rgba(255, 200, 1, 0.995)
}

.image{
    width: 100%;
    height: 100%;
}

.fromgroup{
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
}
  
  .forminput {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: transparent;
    color: white;
    outline: none;
    border-bottom:2px solid  #999;
  }
 
.forminput:focus{
  border-bottom: 2px solid yellow;
}

.submit{
  width: 7rem;
  padding: 5px 5px;
  background-color: rgba(255, 200, 1, 0.995);
  color: white;
  font-weight: bold;
  font-family: monospace;
  letter-spacing: 2px;
  text-align: center;
  border: none;
  /* margin-left: 90px; */
  }

.submit:hover{
  background-color: black;
  border: 2px solid white;
}

/* @media only screen and (min-width: 1440px){
  .missioncol{
    height: 100%;
  }
} */