.sign{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.count{
    background-color: black;
    padding: 7px;
}

.number{
    color: rgb(255, 191, 0);
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    margin: 0px 0px 5px 0px;
}

.valueText{
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
/* border 1 */

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 80%, gray 85%) 1;
}

.border1::after {
    content: '80%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border2 */

.border2 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 95%, gray 78%) 1;
}

.border2::after {
    content: '95%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 3 */

.border3 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 90%, gray 65%) 1;
}

.border3::after {
    content: '90%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* border 4 */

.border4 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 82%, gray 40%) 1;
}

.border4::after {
    content: '82%';
    position: absolute;
    top: 5px;
    right: -10px;
}

/* Border 5 */

.border5 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 55%, gray 25%) 1;
}

.border5::after {
    content: '55%';
    position: absolute;
    top: 5px;
    right: -10px;
}

.border1 {
    position: relative;
    border: 2px solid;
    width: 100%;
    border-image: linear-gradient(to right, rgb(255, 191, 0) 85%, gray 85%) 1;
}

.border1::after {
    content: '85%';
    position: absolute;
    top: 5px;
    right: -10px;
}
