.galryimage{
    width: 100%;
    height: 100%;
}

.galryimage:hover{
    opacity: 0.7;
}

.btnmore{
    text-align: right;
    margin-top: 10px;
}

.more{
    background-color: rgb(255, 153, 0);
    padding: 7px 20px;
    border: none;
    border-radius: 2px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
}


@media only screen and (min-width: 320px) and (max-width: 349px){
    .gallerymt{
     margin-top: 1300px !important;
    }
 }

 @media only screen and (min-width: 349px) and (max-width: 379px){
    .gallerymt{
     margin-top: 1250px !important;
    }
 }

 @media only screen and (min-width: 349px) and (max-width: 400px){
    .gallerymt{
     margin-top: 1150px !important;
    }
 }

 @media only screen and (min-width: 400px) and (max-width: 504px) {
    .gallerymt{
     margin-top: 1050px !important;
    }
 }

 @media only screen and (min-width: 504px) and  (max-width: 576px){
    .gallerymt{
     margin-top: 960px !important;
    }
 }

 @media only screen and (min-width: 576px) and  (max-width: 992px) {
    .gallerymt{
     margin-top: 600px !important;
    }
 }

 @media only screen and  (max-width: 321px) {
   .gallerymt{
    margin-top: 1400px !important;
   }
}