.service{
    font-family: "DM Serif Text", serif;
    text-align: center;
  }
  
  .cardImgZoom {
    transition: transform 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
  }
    
    .cardImgZoom:hover {
      transform: scale(0.9); /* Increase the scale value for more zoom */
    }
  
    @media only screen and (max-width: 575px){
      .cardImgZoom{
        margin-top: 10px !important;
      }
    }
    