.aboutCompany{
    background-color: black;
    opacity: 0.8;
    position: relative;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    height: 100vh; 
  }
  

/* .about-carousel{
   margin: 0px 210px;
   overflow: hidden;
   position: relative;
   opacity: 1;
   z-index: 1;
} */

.carouselImgs{
    /* max-width: 1000px;
    max-height: 450px; */
    width: 100%;
    opacity: 1;
    transition: transform 3s ease;
}

.carouselImgs:hover{
    transform: scale(1.6);
    opacity: 1;
}

.aboutContent{
    position: absolute;
    top: 250px;
    right: 70px;
    width: 400px;
    height: auto;
    padding: 15px 20px;
    background-color: white;
    color: black;
    opacity: 1;
    z-index: 2;
}

.aboutContent .commit{
    opacity: 1;
    font-size: 28px;
}

.aboutContent .commits{
    opacity: 1;
    font-size: 15px;
}

.aboutBox{
    border: 20px solid gray;
    position: absolute;
    opacity: 0.2;
    top: 200px;
    left: 100px;
    /* z-index: 1; */
  }

.boxGray{
    width: 550px;
    height: 360px;
    background: transparent;
}

.aboutBottom{
    font-size: 80px;
    font-weight: 900;
    margin-top: 50px;
    margin-bottom: 40px;
}


@media only screen and (min-width: 2560px) {

    .aboutContent{
        position: absolute;
        top: 350px;
        right: 300px;
        width: 600px;
        height: auto;
        padding: 30px 40px;
        background-color: white;
        color: black;
        opacity: 1;
        z-index: 2;
    }

    .aboutContent p{
        opacity: 1;
        font-size: 25px;
    }
  }

  
/* @media only screen and (min-width: 768px) and (max-width: 991px) {
    
    .aboutBottom{
        text-align: left;
        font-size: 80px;
        font-weight: 900;
    }
    
  } */

   
@media only screen and (min-width: 1200px) and (max-width: 2000px) {
/* 
    .aboutContent {
       display: none;
    } */
    
    .aboutContent p{
        opacity: 1;
        font-size: 20px;
    }
    
  }

       
@media only screen and (min-width: 922px) and (max-width: 992px) {

    .carouse{
        /* height: 50vh; */
    }

    .aboutBottom{
        font-size: 80px;
        font-weight: 900;
        /* margin-top: 400px; */
        /* margin-bottom: 30px; */
    }
    

   }

   @media only screen and (min-width: 768px) and  (max-width: 900px) {


    .aboutContent{
        position: absolute;
        top: 230px;
        right: 20px;
        width: 350px;
        height: auto;
        padding: 15px 20px;
        background-color: white;
        color: black;
        opacity: 1;
        z-index: 2;
    }
    

   }

   @media only screen and (min-width: 667px) and (max-width: 768px) {


    .aboutContent{
       display: none;
    }

    .carouse{
        height: 40vh !important;

    }
    .aboutBottom{
        text-align: left;
    }
    
   }
   
   @media only screen and (min-width: 480px) and (max-width: 667px) {


    .aboutContent{
       display: none;
    }

    .carouse{
        height: 40vh !important;
        margin-left: 20px;

    }
    .aboutBottom{
        text-align: left;
        margin-top: 0px;
    }
    
   }

   @media only screen and (min-width: 360px) and (max-width: 480px) {


    .aboutContent{
       display: none;
    }

    .carouse{
        height: 30vh !important;
        margin-left: -20px;
        padding: 0;

    }
    .aboutBottom{
        text-align: left;
        font-size: 40px;
        margin-top: 0px;
    }

   }

   @media only screen and (max-width: 360px) {


    .aboutContent{
       display: none;
    }

    .carouse{
        height: 25vh !important;
        margin-left: -30px;
        padding: 0;

    }
    .aboutBottom{
        text-align: left;
        font-size: 40px;
        margin-top: 0px;
    }

   }

   @media only screen and (min-width: 2000px) and (max-width: 2558px) {
    .carouselImgs{
        /* max-width: 1000px;
        max-height: 450px; */
        width: 90%;
        opacity: 1;
    }

    .mx-auto{
        margin: 0 0;
    }

    .aboutContent{
        position: absolute;
        top: 250px;
        right: 150px;
        width: 400px;
        height: auto;
        padding: 15px 20px;
        background-color: white;
        color: black;
        opacity: 1;
        z-index: 2;
    }

   }

   @media only screen and (min-width: 2559px) {
   
    .aboutContent{
        position: absolute;
        top: 300px;
        right: 400px;
        width: 400px;
        height: auto;
        padding: 15px 20px;
        background-color: white;
        color: black;
        opacity: 1;
        z-index: 2;
    }

    .aboutCompany .tv-screen {
        margin-left: -190px !important;
    }

    .carouselImgs{
        width: 75%;
        opacity: 1;
    }

    .mx-auto{
        margin: 0 0;
   }

}
/* 
   @media only screen and (min-width: 2560px) {
    .aboutCompany .tv-screen {
        margin-left: -150px !important;
    }

    .carouselImgs{
        width: 80%;
        opacity: 1;

    }

    .mx-auto{
        margin: 0 0;
    }
    
 } */
