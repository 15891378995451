/* HeaderStyle.css */

/* Your existing styles */
.head {
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 15px; */
    list-style: none;
    /* margin-left: 40%; */
    margin-bottom: 30px;
  }
  
  .filter_li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .para {
    position: absolute;
    top: 40%;
    left: 42%;
   font-size: 40px;
   color: white;
   font-weight: bold;
  }

  .para p{
    text-align: center;
  }
  
  .mainimg{
    position: relative;
    width: 100%; /* adjust this according to your layout */
    height: 100%;
  }
  .active {
    background-color: #F5BF23;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .active:hover {
    background-color: #F5BF23;
  }
  
  /* Additional button style */
  .button {
    background-color: #080808;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    /* margin: 2px; */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #0b0c0c;
  }

@media screen and (min-width: 884px) and (max-width: 992px){
  .para {
    top: 40%;
    left: 41%;
   font-size: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 884px){
  .para {
    top: 40%;
    left: 39%;
  }
}

@media screen and (min-width: 573px) and (max-width: 768px){
  .para {
    top: 40%;
    left: 37%;
  }
}

@media screen and (max-width: 612px){
 
  .active {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 13px;
  }
  
  /* Additional button style */
  .button {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 13px;

  }
}

@media screen and (min-width: 455px) and (max-width: 572px){
  .para {
    left: 32%;
   font-size: 40px;
   color: white;
   font-weight: bold;
  }
}

@media screen and (min-width: 373px) and (max-width: 454px){
  .para {
    left: 28%;
   font-size: 40px;
  }
}

@media screen and (min-width: 241px) and (max-width: 372px){
  .para {
    top: 40%;
    left: 30%;
   font-size: 25px;

  }

  .active {
    padding: 10px 10px;
    font-weight: bold;
    font-size: 11px;
  }
  
  /* Additional button style */
  .button {
    padding: 10px 10px;
    font-weight: bold;
    font-size: 11px;
  }
}


@media screen and (max-width: 240px){
  .para {
    position: absolute;
    top: 40%;
    left: 25%;
   font-size: 25px;
   color: white;
   font-weight: bold;
  }

  .active {
    background-color: #F5BF23;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 7px;
    font-weight: bold;
    font-size: 11px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  /* Additional button style */
  .button {
    background-color: #080808;
    border: none;
    color: white;
    padding: 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-weight: bold;
    font-size: 11px;
    /* margin: 2px; */
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
}









  /* Responsive adjustments */
  /* @media screen and (min-width: 380px) and (max-width: 499px) { */
    /* .para {
          top: 150px; 
  margin-left: 300px;
     font-size: 15px;
     font-weight: bold;
    } */
  /* }
  @media screen and (min-width: 3000px) and (max-width: 4000px) {
    .para {
      top: 390px; 
margin-left: 43%;
 font-size: 35px;
 font-weight: bold;
}
  }
  @media screen and (min-width: 1000px) and (max-width: 2560px) {
    .para {
      top: 100px; 
margin-left: 43%;
 font-size: 20px;
 font-weight: bold;
}
  }
  @media screen and (min-width: 1000px) and (max-width: 3000px) {
   .para {
          top: 390px; 
  margin-left: 43%;
     font-size: 35px;
     font-weight: bold;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 2560px) {
    .filter_li {
      margin-left: 0;
      display: flex; 
      justify-content: center; 
      font-size: 10px;
    }
  }
  
  @media screen and (min-width: 320px) and (max-width: 500px) {
    .filter_li {
      margin-left: 0; 
   display: flex;
   justify-content: center; 
  
    }
    .button{
        font-size: 10px;
        padding: 5px 5px;
    }
    .active{
        font-size: 10px;
        padding: 5px 5px;
    }
    .para {   
      top: 250px; 
  margin-left: 150px;
     font-size: 20px;
     font-weight: bold;
    }
  }
  
  @media screen and (min-width: 501px) and (max-width: 767px) {
    .filter_li {
        margin-left: 0;
        display: flex; 
        justify-content: center; 
      }
      .button{
        font-size: 10px;
        padding: 5px 5px;
    }
    .active{
        font-size: 10px;
        padding: 5px 5px;
    }
    .para {   
      top: 190px; 
  margin-left: 120px;
     font-size: 10px;
     font-weight: bold;
    }
  }

  @media screen and (min-width: 50px) and (max-width: 320px) {
    .filter_li {
        margin-left: 0;
        display: flex; 
        justify-content: center; 
      }
      .button{
        font-size: 5px;
        padding: 5px 5px;
    }
    .active{
        font-size: 5px;
        padding: 5px 5px;
    }

    .para {   
      top: 190px; 
  margin-left: 120px;
     font-size: 10px;
     font-weight: bold;
    }
  } */
  
  
 