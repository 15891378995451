 .index {
    background-color: rgb(232, 230, 230);
}

.navbartop{
position: sticky;
top:0px;
z-index: 1;
}
.arrow {
    position: absolute;
    top: 45%;
    border-style: none;
    left: 94%; 
  
    transform: translateY(-30%);
  }
.custom-link{
    text-decoration: none;
    color: black;
    padding: 5px;
}
.buttonapp {
    background-color: rgb(230, 170, 73);
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .buttonapp:hover {
    background-color: #000; 
    color: #fff;  
  }
  
.icons{
    height:17px;
    width:20px;
    background-color: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.01);  
    color: black;
}
@keyframes arrowAnimation {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(-3px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .arrowanimation {
    animation: arrowAnimation 0.8s infinite;
    font-size: 17px;
   
  }
  
  /* Add this CSS to your Navigationgallery.css file */

.modal {
    display: flex !important;
    align-items: center;
  }
  
  .modal-dialog {
    min-width: 50%;
    max-width: 50%;
    margin-left: auto !important;
  }
  
  .modal-content {
    height: 100%;
  }
  
  .modal-body {
    overflow-y: auto;
  }
  
  .modal-right {
    margin-right: auto;
  }
  .logo-img {
    width: 20%; 
    height: auto; 
  }
  
  
  .modal-right{
    flex-direction: column;
    align-items: center;
  }
  
  .modal-right{
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* For screens larger than or equal to 768px */
  @media screen and (min-width: 50px) and (max-width: 302px) {
    .nav1 .custom-link,
    .nav1 .icons {
      display: none; 
    }
    .index .buttonapp {
        padding: 5px;
        font-size: 10px;
        margin-right: 50px;
      } 
      .arrow{
        left: 65%; 
        font-size: 5px;
      }
  }
  @media screen and (min-width: 90px) and (max-width: 277px) {
    .nav1 .custom-link,
    .nav1 .icons {
      display: none; 
    }
    .arrow{
        left: 40%; 
        font-size: 5px;
       top:80px;
     
      }
  }
  @media screen and (min-width: 303px) and (max-width: 767px) {
    .nav1 .custom-link,
  .nav1 .icons {
    display: none; 
  }
    .index .buttonapp {
    padding: 10px;
    font-size: 15px;
    margin-right: 100px;
  } 
  .arrow{
    left: 70%; 
    top:80px;
  }
  }
  @media screen and (min-width: 150px) and (max-width: 304px) {
    .nav1{
        display: flex;
        flex-direction: column; 
        font-size: 10px;
    }
    .index .buttonapp {
        padding: 5%;
        font-size: 5px;
      } 
      .arrow{
        top:30px;
      }

  }
  @media screen and (min-width: 305px) and (max-width: 768px){
    .arrow{
        top:30px;
      }
  }
  @media screen and (min-width: 1440px) and (max-width: 2756px) {
    .nav1{
        display: flex;   
        font-size: 20px;
    }
    .arrow{
        left: 75%; 
        
      }
  }
  @media screen and (min-width: 1399px) and (max-width: 2395px) {
    .arrow{
        left: 97%; 
      }
  }
  @media screen and (min-width: 2394px)and (max-width: 2481px) {
    .arrow{
      left: 95%; 
    }
  }

  @media screen and (min-width: 768px) and (max-width: 992px) {
    .arrow{
        left: 77%; 
        top:30px;
      }
  }
  @media screen and (min-width: 992px) and (max-width: 1249px) {
    .arrow{
        left:96%; 
      }
  }
  