.formHeading{
    color: rgba(255, 153, 0, 0.721);
}

.form {
    background-color: black;
    color: white;
  }
  
  .formContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .emailInput {
    width: calc(33.33% - 5px); 
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: white;
  }
  
  .more {
    /* width: 100%; */
    padding: 10px 15px;
    margin-top: 10px;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    background-color: rgb(255, 153, 0);
    cursor: pointer;
  }
  
  .more:hover {
    background-color: rgb(255, 132, 0);
  }
  
@media only screen and (max-width: 992px){
    .formContainer{
      flex-direction: column;
    }

    .emailInput {
      width: calc(100%); 
    }
}