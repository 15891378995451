.services{
    background-color: black;
    color: white;
    padding: 50px 50px 230px 50px;
    z-index: -1;
}

.servicesContent{
    font-size: 30px;
    font-weight: 700;
}

.servicesrow{
    position: absolute;
    top: 240px;
    left: 0;
    right: 0;
}

.number{
    font-size: 90px;
    font-weight: 800;
}

.servicesflex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card{
    border-radius: 0 !important;
}
.card:hover{
    background-color: white;
}

.border{
   border: 5px solid rgba(255, 196, 0, 0.993);
   width: calc(100% - 100px);
   margin: 0px 50px;
}

.card:hover .servicesflex{
    color: rgba(255, 196, 0, 0.993);    
}

.Cards:hover .border {
    border: 5px solid rgba(255, 196, 0, 0.993);
    width: calc(100% - 0px); /* Adjusting for the additional border width */
    margin-left: 0px; /* Adjusting for the additional border width */
    z-index: 1;
    transition: width 0.3s ease, margin-left 0.3s ease; /* Adding transition for width and margin-left */
}

.Cards:hover .card .image {
    transform: scale(1.1); /* Increase the scale of the image on hover */
    transition: transform 0.3s ease; /* Transition for the scale */
}

.servicesBottom{
    margin-top: 200px;
}

@media only screen and (min-width: 576px) and (max-width: 768px){
    .servicesContent{
        font-size: 25px;
        font-weight: 500px;
    }
}


@media only screen and (max-width: 576px){
    .servicesContent{
        font-size: 18px;
        font-weight: 500px;
    }

    .Cards{
        padding-top: 10px;
    }
}

@media only screen and (min-width: 913px) and (max-width: 992px){
    .servicesBottom{
        margin-top: 400px !important;
    }
}

@media only screen and (min-width: 663px)  and (max-width: 912px){
    .servicesBottom{
        margin-top: 450px !important;
    }
}

@media only screen and (min-width: 577px)  and (max-width: 662px){
    .servicesBottom{
        margin-top: 590px !important;
    }
}

@media only screen and (min-width: 575px) and (max-width: 576px){
    .servicesBottom{
        margin-top: 700px !important;
    }
}

@media only screen and (min-width: 504px) and (max-width: 574px){
    .servicesBottom{
        margin-top: 1050px !important;
    }
}

@media only screen and (min-width: 379px) and (max-width: 504px){
    .servicesBottom{
        margin-top: 1130px !important;
    }
}

@media only screen  and (min-width: 294px)  and (max-width: 378px){
    .servicesBottom{
        margin-top: 1290px !important;
    }
} 

@media only screen and (max-width: 294px){
    .servicesBottom{
        margin-top: 1400px !important;
    }
} 


@media only screen and (max-width: 316px){
    .cardContent{
        font-size: 5px;
    }
}



