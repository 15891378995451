.galryimage{
    width: 100%;
    height: 100%;
}

.galryimage:hover{
    opacity: 0.7;
}

.btnmore{
    text-align: right;
    margin-top: 10px;
}

.more{
    background-color: rgb(255, 153, 0);
    padding: 7px 20px;
    border: none;
    border-radius: 2px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    text-decoration: none;
}
