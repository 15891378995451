.allservice{
    background-color: rgba(128, 128, 128, 0.11);
    text-align: center;
    color: black;
}

.allservice1{
    background-color: black;
    text-align: center;
    color: white;
}

.allImg{
    width: 80px;
    height: 80px;
    background-color: black;
    padding: 20px;
    border-radius: 50%;
}

.allImg1{
    width: 80px;
    height: 80px;
    background-color: rgba(128, 128, 128, 0.603);
    padding: 20px;
    border-radius: 50%;
}

.allservice:hover .allImg {
    transform: scale(1.1); /* Increase the scale of the image on hover */
    transition: transform 0.3s ease; /* Transition for the scale */
}