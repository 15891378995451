.cc .col1{
    width: 50%;
   margin-top:5%;
}
.formcontrol{
    padding: 20px;
    margin-top: 20px;
    border-radius: none!important;
    border: 1px solid #ddd;
    font-size: 15px;
}
.spann{
    color:#F5BF23;
    margin-bottom: 20px;
    font-size: 1.5rem;
}
.col2{
    margin-top:5%;
    margin-bottom: -7rem;
    background-color: black;
    color: white;
    
}
.c2{
    display: flex;
    align-items: center;
}
.contact{
    color: aliceblue;
    text-decoration: none;
    margin-top: 15%;
   margin-right: 50px;
   margin-left: -70px;
font-size: 24px;
}
.font{
    font-size:24px;
}
.h{
    font-size: 25px;
}
.site-button.btn-effect {
    margin-left: 70%;
    margin-bottom: 5px;
    background-color: #F5BF23;
    color: #fff;
    border: none;
    padding: 10px 25px;
    font-size: 24px;
   
}
.maps{
    margin-top: 10%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    margin-left: 4%;
}
@media screen and (min-width: 320px) and (max-width: 426px) {
.formcontrol{
    padding: 0px 0px;
    margin-left: 10px;
    width: 180%;
}
.spann{
    font-size: 1rem;
}
.site-button.btn-effect{
    padding:0px 4px;
    font-size:10px;
  margin-left: 120px;
}
.h{
    font-size: 10px;
}
.contact{
    font-size: 10px;
   
}
.font{
    font-size: 15px;
}
.ms-3 {
    margin-left: 0px;
    padding: 1px 5px;
}
.col2{
    margin-bottom: -2rem;
}

.c2{
    margin-bottom: 5%;
 }
}
@media screen and (min-width: 200px) and (max-width: 320px) {
    .formcontrol{
        padding: 0px 0px !important;
        margin-left: 0px;
        width: 180%;
    }
    .site-button.btn-effect{
        padding:0px 3px;
        font-size:10px;
      
            margin-left: 100px;
    }
    .h{
        font-size: 10px;
    }
    .contact{
        font-size: 10px;
      
    }
    .font{
        font-size: 15px;
    }
    .ms-3 {
        margin-left: 0px;
        padding: 1px 5px;
    }
    .col2{
        margin-bottom: -1rem;

    }
    .spann{
        font-size: 1rem;  
    }
    /* .maps{
        margin-top: 25%;
    } */
    .c2{
        margin-bottom: 6%;
     }

}
@media screen and (min-width: 140px) and (max-width: 199px) {
    .formcontrol{
        padding: 1px 1px;
        margin-left: 40px;
        font-size: 20px;
    }
    .site-button.btn-effect{
        padding:0px 3px;
        font-size:10px;
      
            margin-left: 60px;
    }
    .h{
        font-size: 9px;
    }
    .contact{
        font-size: 9px;
      
    }
    .font{
        font-size: 10px;
    }
    .ms-3 {
        margin-left: 0px;
        padding: 1px 5px;
    }
    .maps{
        margin-top: 15%;
    }
    .c2{
        margin-bottom: 5%;
     }
}
@media screen and (min-width: 426px) and (max-width: 992px) {
    .formcontrol{
        padding: 2px 2px;
        width: 200%;
        font-size: 10px;
    }
    .h{
        font-size: 15px;
    }
    .font{
        font-size: 15px;
    }
    .contact{
        font-size: 15px;
    }
    .maps{
        margin-top: 12%;
    }
    .c2{
        margin-bottom: 5%;
     }
     .site-button.btn-effect{
        padding:5px 5px;
        font-size:15px;
      margin-left: 200px;
    }
}
@media screen and  (min-width: 992px) {
    .formcontrol{
        padding: 2px 2px;
        width: 100%;
       
    }
    .site-button.btn-effect{
        padding:5px 5px;
        font-size:15px;
      
            margin-left: 90%;
    }
    .h{
        font-size: 20px;
    }
    .font{
        font-size: 20px;
    }
    .contact{
        font-size: 20px;
    }
    .maps{
        margin-top: 14%;
    }
    .c2{
        margin-bottom: 5%;
     }

}
@media screen and (min-width: 427px) and (max-width: 697px) {
    .maps{
        margin-top: 27%;
    } 
    .c2{
        margin-bottom: 5%;
     }
      
}
@media screen and (min-width: 733px) and (max-width: 990px) {
    .maps{
        margin-top: 17%;
    } 
    .c2{
        margin-bottom: 5%;
     }
     .site-button.btn-effect{
        padding:3px 5px;
        font-size:10px;
      
            margin-left: 220px;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
    .site-button.btn-effect{
        padding:3px 5px;
        font-size:10px;
      
            margin-left: 350px;
    }
}
@media screen and (min-width: 696px) and (max-width: 733px) {
    .maps{
        margin-top: 17%;
    } 
     .c2{
        margin-bottom: 5%;
     }
   
}
@media screen and (min-width: 992px) and (max-width: 1400px) {
    .col2{
        margin-bottom: -1rem;

    }
    .maps{
        margin-top: 5%;  
        width:94%  
    }
}