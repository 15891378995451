/* ChildComponent.css */
.gallery {
    width: 100%;
    max-width: 1200px; /* Limiting gallery width for larger screens */
    margin: 0 auto; /* Center aligning the gallery */
  }
  
  .image-container {
    position: relative;
    margin-right: 20px;
    margin-bottom: 20px;
    width: 30%;
    cursor: pointer;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .image-hover-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(255, 255, 255);
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    transition: 0.6s ease;
  }
  
  /* Responsive adjustments */
  @media screen and(max-width: 768px) {
    .image-container {
      width: 40%; 
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
  